var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, Fragment, useEffect, useMemo } from 'react';
import { PageTitle } from '@src/_metronic/layout/core';
import { KTCard } from '@src/_metronic/helpers';
import DataTable from 'react-data-table-component';
import '@src/app/styles/datatable.css';
import { useTransfer } from './Transfer.hooks';
import Select from '@src/components/Atoms/Select/Select';
import Input from '@src/components/Atoms/Input/Input';
import RecordTalent from '@src/components/molecules/Record/Talent';
import RecordTransfer from '@src/components/molecules/Record/Collaboration';
import RecordDescription from '@src/components/molecules/Record/Description';
import { MultiDimensionalFilter, ProtectedUrl, paginationOptions, optionTransferCEType, optionTransferFType, transferOptions, userInformation, RoleList, optionBankList, DefaultAllList, FormatYearMonthDate, FormatDateTime, } from '@src/app/helper';
import { useLocation, useNavigate } from 'react-router-dom';
import InputTransferButton from '@src/components/Atoms/Buttons/InputTransferButton';
import BelumTransferButton from '@src/components/Atoms/Buttons/BelumTransferButton';
import OtoTransferButton from '@src/components/Atoms/Buttons/OtoTransferButton';
import GagalTransferButton from '@src/components/Atoms/Buttons/GagalTransferButton';
import DoneTransferButton from '@src/components/Atoms/Buttons/DoneTransferButton';
import DoneTransferCEButton from '@src/components/Atoms/Buttons/DoneTransferCEButton';
import RefundTransferButton from '@src/components/Atoms/Buttons/RefundTransferButton';
import DrawerForm from '@src/components/molecules/Drawer/DrawerForm';
import UploadForm from '@src/components/Atoms/Drawer/TransferFormDrawer/UploadBuktiTransferForm';
import TransferForm from '@src/components/Atoms/Drawer/TransferFormDrawer/SudahTransferForm';
import FailedForm from '@src/components/Atoms/Drawer/TransferFormDrawer/GagalTransferForm';
import RefundForm from '@src/components/Atoms/Drawer/TransferFormDrawer/RefundTransferForm';
import HoldForm from '@src/components/Atoms/Drawer/TransferFormDrawer/HoldTransferForm';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { MenuComponent } from '@src/_metronic/assets/ts/components';
import { bulkAuthTransfers, cancelTransfers, completedTransfers, doneTransfers, failedInputTransfers, failedTransfers, fixTransfers, incorrectTransfers, inputTransfers, refundCompletedTransfers, refundTransfers, uploadProofTransfer, } from './Transfer.requests';
import { toast } from 'react-toastify';
import GagalInputForm from '@src/components/Atoms/Drawer/TransferFormDrawer/GagalInputForm';
import NotFound from '@src/components/Atoms/NotFound/NotFound';
import { holdJobs, uploadFile } from '../../JobPage/Job/Job.requests';
import { useSocialMedia } from '../../SocialMediaPage/SocialMedia/SocialMedia.hooks';
import Loading from '@src/components/Atoms/Loading/Loading';
import RecordAdmin from '@src/components/molecules/Record/Admin';
import TemplateCetak from '@src/components/molecules/Transfer/TemplateCetak';
import { useTeams } from '../../TeamPage/TeamHooks/Team.hooks';
import swal from 'sweetalert';
import { useIntl } from 'react-intl';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';
import RecordJobTransfer from '@src/components/molecules/Record/JobTransfer';
//breadcrumb
var usersBreadcrumbs = [
    {
        title: 'Transfer',
        path: '',
        isSeparator: true,
        isActive: false,
    },
];
var HoldFormSchema = Yup.object().shape({
    reason: Yup.string().required('Field belum diisi'),
});
var HoldValues = {
    reason: '',
};
var TransferIndex = function () {
    var intl = useIntl();
    var location = useLocation();
    var navigate = useNavigate();
    var role = JSON.parse(userInformation).roles[0];
    var _a = useState(role == RoleList.CampaignExecutive ? transferOptions.belum : transferOptions.input), transferType = _a[0], setTransferType = _a[1];
    var _b = useState(false), isOpenAdd = _b[0], setIsOpenAdd = _b[1];
    var _c = useState(false), isOpenFailTransfer = _c[0], setIsOpenFailTransfer = _c[1];
    var _d = useState(false), isOpenFixTransfer = _d[0], setIsOpenFixTransfer = _d[1];
    var _e = useState(false), isOpenDoneTransferForm = _e[0], setIsOpenDoneTransferForm = _e[1];
    var _f = useState(false), isOpenUploadTransferForm = _f[0], setIsOpenUploadTransferForm = _f[1];
    var _g = useState(false), isOpenRefundTransferForm = _g[0], setIsOpenRefundTransferForm = _g[1];
    var _h = useState(false), isOpenCetak = _h[0], setIsOpenCetak = _h[1];
    var _j = useState(''), tabName = _j[0], setTabName = _j[1];
    var _k = useState({
        page: 1,
        pageSize: 10,
        keyword: '',
        filterBy: '[]',
        Type: role == RoleList.CampaignExecutive ? transferOptions.belum : transferOptions.input,
    }), filter = _k[0], setFilter = _k[1];
    var _l = useState(), activeRecord = _l[0], setActiveRecord = _l[1];
    var _m = useState(), transferRecord = _m[0], setTransferRecord = _m[1];
    var _o = useState(), fixTransferRecord = _o[0], setFixTransferRecord = _o[1];
    var _p = useTransfer(filter), transfers = _p.transfers, isLoading = _p.isLoading, refetchTransfers = _p.refetch;
    var socialMedia = useSocialMedia().socialMedia;
    var team = useTeams().team;
    var teams = { data: {} };
    if (role === RoleList.CampaignExecutive) {
        socialMedia = JSON.parse(userInformation).socialMedias;
        teams = JSON.parse(userInformation).teams;
    }
    var optionSocialMedia = socialMedia &&
        socialMedia.map(function (social) { return ({
            name: social.name,
            value: social.pubId,
        }); });
    role !== RoleList.CampaignExecutive &&
        optionSocialMedia &&
        optionSocialMedia.unshift(DefaultAllList);
    var optionTeam = role === RoleList.CampaignExecutive
        ? teams &&
            teams.map(function (team) { return ({
                name: team.name,
                value: team.pubId,
            }); })
        : (team === null || team === void 0 ? void 0 : team.data) &&
            team.data.map(function (team) { return ({
                name: team.name,
                value: team.pubId,
            }); });
    role !== RoleList.CampaignExecutive && optionTeam && optionTeam.unshift(DefaultAllList);
    var handleMultiDimensionalFilterChange = function (event) {
        var filterValue = filter.filterBy !== '' ? JSON.parse(filter.filterBy) : '';
        if (filter.filterBy === '') {
            var filterCriteria = [[event.target.name, event.target.value]];
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterCriteria) }));
        }
        else {
            var filterNew = MultiDimensionalFilter(event, filterValue);
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterNew) }));
        }
    };
    var handleFilterChange = function (event, key) {
        var _a, _b, _c;
        if (key) {
            setTransferType(key);
            navigate("#".concat(key));
            var filterValue = JSON.parse(filter.filterBy);
            var newFilterValue = filterValue
                .filter(function (filter) { return filter[0] !== 'date'; })
                .map(function (value) {
                return value;
            });
            if (key == transferOptions.input || key == transferOptions.belum || key == transferOptions.upcoming) {
                var filterValue_1 = filter.filterBy !== '' ? JSON.parse(filter.filterBy) : '';
                var filterCriteria = ['date', date];
                if (filter.filterBy === '') {
                    setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterCriteria) }));
                }
                else {
                    !filter.filterBy.includes('date') && filterValue_1.push(filterCriteria);
                    setFilter(__assign(__assign({}, filter), (_a = { filterBy: JSON.stringify(filterValue_1) }, _a[event.target.name] = key, _a)));
                }
            }
            else {
                setFilter(__assign(__assign({}, filter), (_b = { filterBy: JSON.stringify(newFilterValue) }, _b[event.target.name] = key, _b)));
            }
        }
        else {
            setFilter(__assign(__assign({}, filter), (_c = {}, _c[event.target.name] = event.target.value, _c)));
        }
    };
    var handlePageChange = function (page) {
        setFilter(__assign(__assign({}, filter), { page: page }));
    };
    var handlePageSizeChange = function (size) {
        setFilter(__assign(__assign({}, filter), { page: 1, pageSize: size }));
    };
    //here
    function changeTransferType(name) {
        var _a;
        setFilter(__assign(__assign({}, filter), (_a = {}, _a[name] = optionBankList[0].value, _a)));
    }
    //select
    var _q = useState([]), selectedRows = _q[0], setSelectedRows = _q[1];
    var _r = useState(false), toggleCleared = _r[0], setToggleCleared = _r[1];
    var handleRowSelected = function (state) {
        setSelectedRows(state.selectedRows);
    };
    var contextActions = useMemo(function () {
        var handleHold = function () {
            setIsOpenAdd(true);
            setTabName('Transfer Hold');
        };
        var handleAuth = function () {
            var record = selectedRows.map(function (data) { return data.pubId; });
            authorizationTransferItem(record);
        };
        return (_jsxs(_Fragment, { children: [_jsx("button", __assign({ onClick: handleHold, className: 'btn btn-sm btn-warning mx-2' }, { children: "Hold" }), 'delete'), transferType == transferOptions.belum && (_jsx("button", __assign({ onClick: handleAuth, className: 'btn btn-sm btn-primary' }, { children: "Otorisasi Transfer" })))] }));
    }, [selectedRows, toggleCleared]);
    var handleCetak = function (e) {
        setIsOpenCetak(true);
    };
    //gotodetail
    var gotoDetail = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            navigate("/talent/detail/".concat(record));
            return [2 /*return*/];
        });
    }); };
    //input transfer
    var doneInputItem = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        var status_1, e_1, error;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    return [4 /*yield*/, inputTransfers(record.pubId)];
                case 1:
                    status_1 = (_a.sent()).status;
                    if (status_1 === 200) {
                        toast.success('Transfer status berhasil diubah menjadi belum transfer');
                    }
                    return [3 /*break*/, 4];
                case 2:
                    e_1 = _a.sent();
                    error = e_1.response.data;
                    toast.error(error.message);
                    return [3 /*break*/, 4];
                case 3:
                    refetchTransfers();
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var failedInputItem = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setIsOpenFailTransfer(true);
            setActiveRecord(record.pubId);
            setTabName('Alasan Gagal Input');
            return [2 /*return*/];
        });
    }); };
    var invoiceItem = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            window.open(record.invoiceUrl, '_blank', 'noreferrer');
            return [2 /*return*/];
        });
    }); };
    var proofItem = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            window.open(record.proofTransferUrl, '_blank', 'noreferrer');
            return [2 /*return*/];
        });
    }); };
    //belum transfer
    var authorizationTransferItem = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            swal({
                title: intl.formatMessage({ id: 'GENERAL.VALIDATION' }),
                text: '',
                icon: 'warning',
                buttons: [
                    intl.formatMessage({ id: 'GENERAL.VALIDATION.NO' }),
                    intl.formatMessage({ id: 'GENERAL.VALIDATION.YES' }),
                ],
                dangerMode: true,
            }).then(function (willApprove) { return __awaiter(void 0, void 0, void 0, function () {
                var status_2, e_2, error;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!willApprove) return [3 /*break*/, 5];
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, 4, 5]);
                            return [4 /*yield*/, bulkAuthTransfers(record)];
                        case 2:
                            status_2 = (_a.sent()).status;
                            if (status_2 === 200) {
                                toast.success('Transfer status berhasil diubah menjadi oto transfer');
                            }
                            return [3 /*break*/, 5];
                        case 3:
                            e_2 = _a.sent();
                            error = e_2.response.data;
                            toast.error(error.message);
                            return [3 /*break*/, 5];
                        case 4:
                            refetchTransfers();
                            return [7 /*endfinally*/];
                        case 5: return [2 /*return*/];
                    }
                });
            }); });
            return [2 /*return*/];
        });
    }); };
    var failedTransferItem = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setIsOpenFailTransfer(true);
            setActiveRecord(record.pubId);
            setTabName('Alasan Gagal Transfer');
            return [2 /*return*/];
        });
    }); };
    var cancelTransferItem = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        var status_3, e_3, error;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    return [4 /*yield*/, cancelTransfers(record.pubId)];
                case 1:
                    status_3 = (_a.sent()).status;
                    if (status_3 === 200) {
                        toast.success('Transfer status berhasil diubah menjadi cancel transfer');
                    }
                    return [3 /*break*/, 4];
                case 2:
                    e_3 = _a.sent();
                    error = e_3.response.data;
                    toast.error(error.message);
                    return [3 /*break*/, 4];
                case 3:
                    refetchTransfers();
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    //oto transfer
    var doneTransferItem = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setTransferRecord(record);
            setIsOpenDoneTransferForm(true);
            setTabName('Done Transfer');
            return [2 /*return*/];
        });
    }); };
    //gagal transfer
    var editGagalTransferItem = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setFixTransferRecord(record);
            setIsOpenFixTransfer(true);
            setTabName('Gagal Transfer');
            return [2 /*return*/];
        });
    }); };
    //done transfer CE
    var completedTransferItem = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        var status_4, e_4, error;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    return [4 /*yield*/, completedTransfers(record.pubId, record.spkFilePubId)]; //there is no spkFilePubId in data
                case 1:
                    status_4 = (_a.sent()) //there is no spkFilePubId in data
                    .status;
                    if (status_4 === 200) {
                        toast.success('Transfer Completed');
                    }
                    return [3 /*break*/, 4];
                case 2:
                    e_4 = _a.sent();
                    error = e_4.response.data;
                    toast.error(error.message);
                    return [3 /*break*/, 4];
                case 3:
                    refetchTransfers();
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var refundTransferItem = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setTransferRecord(record);
            setIsOpenRefundTransferForm(true);
            setTabName('Refund Transfer');
            return [2 /*return*/];
        });
    }); };
    //done transfer
    var uploadTransferItem = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setIsOpenUploadTransferForm(true);
            setTransferRecord(record);
            setTabName('Bukti Transfer');
            return [2 /*return*/];
        });
    }); };
    var wrongTransferItem = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        var status_5, e_5, error;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    return [4 /*yield*/, incorrectTransfers(record.pubId)];
                case 1:
                    status_5 = (_a.sent()).status;
                    if (status_5 === 200) {
                        toast.success('Transfer status berhasil diubah menjadi salah transfer');
                    }
                    return [3 /*break*/, 4];
                case 2:
                    e_5 = _a.sent();
                    error = e_5.response.data;
                    toast.error(error.message);
                    return [3 /*break*/, 4];
                case 3:
                    setTimeout(function () {
                        refetchTransfers();
                    }, 2000);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    //refund transfer
    var refundCompletedItem = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        var status_6, e_6, error;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    return [4 /*yield*/, refundCompletedTransfers(record.pubId)];
                case 1:
                    status_6 = (_a.sent()).status;
                    if (status_6 === 200) {
                        toast.success('Transfer status berhasil direfund');
                    }
                    return [3 /*break*/, 4];
                case 2:
                    e_6 = _a.sent();
                    error = e_6.response.data;
                    toast.error(error.message);
                    return [3 /*break*/, 4];
                case 3:
                    setTimeout(function () {
                        refetchTransfers();
                    }, 2000);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    // TODO: show cp email and cp other and country name on datatable row, as its missing from api return
    var columns = [
        {
            name: 'No.Transfer',
            cell: function (record) {
                return _jsx(RecordJobTransfer, { transfers: record.jobs.map(function (data) { return data.jobTransfers; }).flat() });
            },
        },
        {
            name: 'Contact Person',
            cell: function (record) { return (_jsx(RecordTalent, { name: record.talent.name, pubId: record.talent.pubId, cpName: record.talent.cpName, cpPhoneNumber: record.talent.cpPhoneNumber, cpEmail: '', onClick: gotoDetail, profiles: [] })); },
            sortable: true,
        },
        {
            name: 'Kerja Sama',
            cell: function (record) {
                return record.jobs.map(function (data, key) { return (_jsx(RecordTransfer, { record: data || '', id: data.pubId }, key)); });
            },
            sortable: true,
        },
        {
            name: 'Keterangan',
            cell: function (record, rowIndex) {
                return (_jsx(RecordDescription, { total: record.totalAmount, bankName: record.talentBankAccount.bank.name, cityName: record.talentBankAccount.city && record.talentBankAccount.city.name, accountNumber: record.talentBankAccount.accountNumber, accountName: record.talentBankAccount.accountName, pajakType: record.pajakType, pajakCategory: record.pajakCategory, pajakMethod: record.pajakMethod, paymentMethod: record.paymentMethod, pajakFee: record.pajakFee, pajakBruto: record.pajakBruto, pajakPPN: record.pajakPPN, bankTransfer: record.bankTransferBy ? record.bankTransferBy.name : '-', invoice: record.invoiceUrl, spk: record.spkUrl, transfer: record.transferUrl }));
            },
            sortable: true,
        },
        {
            name: 'Note',
            cell: function (record) {
                return (transferType == transferOptions.reject ? record.canceledReason ? _jsx("p", { children: record.canceledReason }) : _jsx("p", __assign({ style: { color: 'red' } }, { children: record.canceledReason }))
                    : record.note ? _jsx("p", { children: record.note }) : _jsx("p", __assign({ style: { color: 'red' } }, { children: record.note })));
            },
            sortable: true,
        },
        {
            name: 'Payment Due',
            cell: function (record) { var _a; return _jsx("p", { children: (_a = FormatDateTime(record.paymentDue)) !== null && _a !== void 0 ? _a : '-' }); },
            sortable: true,
            omit: transferType !== transferOptions.upcoming ? true : false,
        },
        {
            name: 'Oleh',
            cell: function (record) { return _jsx(RecordAdmin, { record: record }); },
            sortable: true,
        },
        {
            name: 'Action',
            cell: function (record) {
                return (_jsxs(_Fragment, { children: [ProtectedUrl('transfer.view') &&
                            ProtectedUrl('transfer.transfer.view') &&
                            transferType == transferOptions.input && (_jsx(InputTransferButton, { doneInputItem: function () { return doneInputItem(record); }, failedInputItem: function () { return failedInputItem(record); }, invoiceItem: record.invoiceUrl ? function () { return invoiceItem(record); } : undefined, record: record }) //input transfer
                        ), ProtectedUrl('transfer.view') &&
                            ProtectedUrl('transfer.transfer.view') &&
                            (transferType == transferOptions.belum ||
                                (record.isInputted &&
                                    !record.isAuthTransfer &&
                                    record.isApproved &&
                                    !record.isTransferCompleted)) && (_jsx(BelumTransferButton, { failedTransferItem: function () { return failedTransferItem(record); }, cancelTransferItem: function () { return cancelTransferItem(record); }, invoiceItem: record.invoiceUrl ? function () { return invoiceItem(record); } : undefined, record: record }) //belum transfer
                        ), ProtectedUrl('transfer.view') &&
                            ProtectedUrl('transfer.transfer.view') &&
                            transferType == transferOptions.oto && (_jsx(OtoTransferButton, { doneTransferItem: function () { return doneTransferItem(record); }, invoiceItem: record.invoiceUrl ? function () { return invoiceItem(record); } : undefined, record: record }) //oto transfer
                        ), ProtectedUrl('transfer.view') &&
                            transferType == transferOptions.gagal &&
                            role === RoleList.CampaignExecutive && (_jsx(GagalTransferButton, { editGagalTransferItem: function () { return editGagalTransferItem(record); }, record: record }) //gagal transfer
                        ), ProtectedUrl('transfer.view') &&
                            (transferType === transferOptions.done ||
                                (record.isAuthTransfer && !record.isTransferCompleted)) &&
                            role === RoleList.CampaignExecutive && (_jsx(DoneTransferCEButton, { completedTransferItem: function () { return completedTransferItem(record); }, refundTransferItem: function () { return refundTransferItem(record); }, proofItem: record.proofTransferUrl ? function () { return proofItem(record); } : undefined, record: record }) //done transfer CE
                        ), ProtectedUrl('transfer.view') &&
                            (transferType == transferOptions.done ||
                                (record.isInputted &&
                                    record.isAuthTransfer &&
                                    record.isApproved &&
                                    !record.isTransferCompleted &&
                                    record.isTransferred)) &&
                            role === RoleList.Finance && (_jsx(DoneTransferButton, { uploadTransferItem: function () { return uploadTransferItem(record); }, wrongTransferItem: function () { return wrongTransferItem(record); }, proofItem: record.proofTransferUrl ? function () { return proofItem(record); } : undefined, record: record }) //done transfer
                        ), ProtectedUrl('transfer.view') &&
                            ProtectedUrl('transfer.transfer.view') &&
                            (transferType == transferOptions.refund ||
                                (record.isRefund && !record.isRefundCompleted)) && (_jsx(RefundTransferButton, { refundCompletedItem: function () { return refundCompletedItem(record); }, record: record }) //refund transfer
                        )] }));
            },
            width: '220px',
            center: true,
        },
    ];
    var HoldDetail = { data: HoldValues }.data;
    var holdValues = {
        reason: (HoldDetail === null || HoldDetail === void 0 ? void 0 : HoldDetail.reason) || '',
    };
    var HoldFormik = useFormik({
        initialValues: HoldDetail ? holdValues : HoldValues,
        enableReinitialize: true,
        validationSchema: HoldFormSchema,
        onSubmit: function (values, _a) {
            var resetForm = _a.resetForm;
            return __awaiter(void 0, void 0, void 0, function () {
                var jobId_1, data, e_7, error;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 2, 3, 4]);
                            jobId_1 = [];
                            if (selectedRows.length > 0) {
                                selectedRows.map(function (data) { return jobId_1.push(data.jobs[0].pubId); });
                            }
                            else {
                                toast.error('You must select any transfer data first');
                            }
                            return [4 /*yield*/, holdJobs(jobId_1, values.reason)];
                        case 1:
                            data = (_b.sent()).data;
                            if (data.message) {
                                toast.success(data.message);
                            }
                            return [3 /*break*/, 4];
                        case 2:
                            e_7 = _b.sent();
                            error = e_7.response.data;
                            toast.error(error);
                            return [3 /*break*/, 4];
                        case 3:
                            setIsOpenAdd(false);
                            refetchTransfers();
                            resetForm();
                            return [7 /*endfinally*/];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        },
    });
    var fixTransferFormik = useFormik({
        initialValues: {
            bankPubId: fixTransferRecord ? fixTransferRecord.bank.pubId : '',
            accountNumber: fixTransferRecord ? fixTransferRecord.accountNumber : '',
            accountName: fixTransferRecord ? fixTransferRecord.accountName : '',
            branchName: fixTransferRecord ? fixTransferRecord.branchName : '',
            cityPubId: fixTransferRecord ? fixTransferRecord.jobs[0].bankAccount.city.pubId : '', //fixTransferRecord.citypubid
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            bankPubId: Yup.string().required('Field belum diisi'),
            accountNumber: Yup.string().required('Field belum diisi'),
            accountName: Yup.string().required('Field belum diisi'),
            branchName: Yup.string().required('Field belum diisi'),
            cityPubId: Yup.string().required('Field belum diisi'),
        }),
        onSubmit: function (values, _a) {
            var resetForm = _a.resetForm;
            return __awaiter(void 0, void 0, void 0, function () {
                var status_7, e_8, error;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 2, 3, 4]);
                            return [4 /*yield*/, fixTransfers(fixTransferRecord.pubId, values)];
                        case 1:
                            status_7 = (_b.sent()).status;
                            if (status_7 === 200) {
                                toast.success('Transfer status berhasil diubah');
                            }
                            return [3 /*break*/, 4];
                        case 2:
                            e_8 = _b.sent();
                            error = e_8.response.data;
                            toast.error(error.message);
                            return [3 /*break*/, 4];
                        case 3:
                            setIsOpenFixTransfer(false);
                            refetchTransfers();
                            resetForm();
                            return [7 /*endfinally*/];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        },
    });
    var failsInputFormik = useFormik({
        initialValues: {
            reason: '',
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            reason: Yup.string().required('Field belum diisi'),
        }),
        onSubmit: function (values, _a) {
            var resetForm = _a.resetForm;
            return __awaiter(void 0, void 0, void 0, function () {
                var status_8, e_9, error;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 5, 6, 7]);
                            if (!tabName.includes('Input')) return [3 /*break*/, 2];
                            return [4 /*yield*/, failedInputTransfers(activeRecord, values.reason)];
                        case 1:
                            status_8 = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, failedTransfers(activeRecord, values.reason)];
                        case 3:
                            status_8 = _b.sent();
                            _b.label = 4;
                        case 4:
                            if (status_8 === 200) {
                                toast.success('Transfer status berhasil diubah');
                            }
                            return [3 /*break*/, 7];
                        case 5:
                            e_9 = _b.sent();
                            error = e_9.response.data;
                            toast.error(error.message);
                            return [3 /*break*/, 7];
                        case 6:
                            setIsOpenFailTransfer(false);
                            refetchTransfers();
                            resetForm();
                            return [7 /*endfinally*/];
                        case 7: return [2 /*return*/];
                    }
                });
            });
        },
    });
    var doneTransferFormik = useFormik({
        initialValues: {
            proofTransferFilePubId: '',
            bankTransferByPubId: '',
            transferDate: '',
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            // proofTransferFilePubId: Yup.mixed().required('Field belum diisi'),
            bankTransferByPubId: Yup.string().required('Field belum diisi'),
            // transferDate: Yup.string().required('Transfer date is required'),
        }),
        onSubmit: function (values, _a) {
            var resetForm = _a.resetForm;
            return __awaiter(void 0, void 0, void 0, function () {
                var proofTransferPubId, proofTransfer, status_9, e_10, error;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 4, 5, 6]);
                            proofTransferPubId = void 0;
                            if (!(values.proofTransferFilePubId !== '')) return [3 /*break*/, 2];
                            return [4 /*yield*/, uploadFile({
                                    File: values.proofTransferFilePubId[0],
                                    TenantPubId: transferRecord.talent.tenant.pubId,
                                    TalentPubId: transferRecord.talent.pubId,
                                    Type: 'ProofOfUploads',
                                })];
                        case 1:
                            proofTransfer = _b.sent();
                            proofTransferPubId = proofTransfer.data.data.pubId;
                            _b.label = 2;
                        case 2: return [4 /*yield*/, doneTransfers(transferRecord.pubId, proofTransferPubId, values.bankTransferByPubId, values.transferDate)];
                        case 3:
                            status_9 = (_b.sent()).status;
                            if (status_9 === 200) {
                                toast.success('Transfer berhasil diubah');
                            }
                            return [3 /*break*/, 6];
                        case 4:
                            e_10 = _b.sent();
                            error = e_10.response.data;
                            toast.error(error.message);
                            return [3 /*break*/, 6];
                        case 5:
                            setIsOpenDoneTransferForm(false);
                            refetchTransfers();
                            resetForm();
                            return [7 /*endfinally*/];
                        case 6: return [2 /*return*/];
                    }
                });
            });
        },
    });
    var uploadProofTransferFormik = useFormik({
        initialValues: {
            proofTransferFilePubId: '',
            bankTransferByPubId: '',
            transferDate: '',
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            proofTransferFilePubId: Yup.mixed().required('Field belum diisi'),
            bankTransferByPubId: Yup.string().required('Field belum diisi'),
            // transferDate: Yup.string().required('Transfer date is required'),
        }),
        onSubmit: function (values, _a) {
            var resetForm = _a.resetForm;
            return __awaiter(void 0, void 0, void 0, function () {
                var proofTransfer, proofTransferPubId, status_10, e_11, error;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 4, 5, 6]);
                            return [4 /*yield*/, uploadFile({
                                    File: values.proofTransferFilePubId[0],
                                    TenantPubId: transferRecord.talent.tenant.pubId,
                                    TalentPubId: transferRecord.talent.pubId,
                                    Type: 'ProofOfTransfers',
                                })];
                        case 1:
                            proofTransfer = _b.sent();
                            if (!(proofTransfer.status === 200)) return [3 /*break*/, 3];
                            proofTransferPubId = proofTransfer.data.data.pubId;
                            return [4 /*yield*/, uploadProofTransfer(transferRecord.pubId, proofTransferPubId, values.bankTransferByPubId
                                // values.transferDate
                                )];
                        case 2:
                            status_10 = (_b.sent()).status;
                            if (status_10 === 200) {
                                toast.success('Transfer berhasil diubah');
                            }
                            _b.label = 3;
                        case 3: return [3 /*break*/, 6];
                        case 4:
                            e_11 = _b.sent();
                            error = e_11.response.data;
                            toast.error(error.message);
                            return [3 /*break*/, 6];
                        case 5:
                            setIsOpenUploadTransferForm(false);
                            refetchTransfers();
                            resetForm();
                            return [7 /*endfinally*/];
                        case 6: return [2 /*return*/];
                    }
                });
            });
        },
    });
    var refundTransferFormik = useFormik({
        initialValues: {
            reason: '',
            proofRefundFilePubId: '',
            totalAmount: 0,
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            reason: Yup.string().required('Field belum diisi'),
            proofRefundFilePubId: Yup.mixed().required('Field belum diisi'),
        }),
        onSubmit: function (values, _a) {
            var resetForm = _a.resetForm;
            return __awaiter(void 0, void 0, void 0, function () {
                var refundTransfer, refundTransferPubId, status_11, e_12, error;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 4, 5, 6]);
                            return [4 /*yield*/, uploadFile({
                                    File: values.proofRefundFilePubId[0],
                                    TenantPubId: transferRecord.talent.tenant.pubId,
                                    TalentPubId: transferRecord.talent.pubId,
                                    Type: 'ProofOfRefunds',
                                })];
                        case 1:
                            refundTransfer = _b.sent();
                            if (!(refundTransfer.status === 200)) return [3 /*break*/, 3];
                            refundTransferPubId = refundTransfer.data.data.pubId;
                            return [4 /*yield*/, refundTransfers(transferRecord.pubId, values.reason, refundTransferPubId, values.totalAmount)];
                        case 2:
                            status_11 = (_b.sent()).status;
                            if (status_11 === 200) {
                                toast.success('Transfer berhasil diubah');
                            }
                            _b.label = 3;
                        case 3: return [3 /*break*/, 6];
                        case 4:
                            e_12 = _b.sent();
                            error = e_12.response.data;
                            toast.error(error.message);
                            return [3 /*break*/, 6];
                        case 5:
                            setIsOpenRefundTransferForm(false);
                            refetchTransfers();
                            resetForm();
                            return [7 /*endfinally*/];
                        case 6: return [2 /*return*/];
                    }
                });
            });
        },
    });
    var _s = useState(dayjs().format('YYYY-MM-DD').toString()), date = _s[0], setDate = _s[1];
    var onChangeDate = function (e, name) {
        var event = { target: { name: 'date', value: null } };
        event.target.value = e == null ? '' : FormatYearMonthDate(e);
        setDate(event.target.value);
        handleMultiDimensionalFilterChange(event);
    };
    useEffect(function () {
        MenuComponent.reinitialization();
        if (!location.hash) {
            if (role == RoleList.CampaignExecutive) {
                navigate("#".concat(transferOptions.belum));
            }
            else {
                navigate("#".concat(transferOptions.input));
            }
        }
    }, []);
    useEffect(function () {
        var filterValue = JSON.parse(filter.filterBy);
        if (optionSocialMedia && optionSocialMedia.length > 0) {
            var filterCriteria = ['division.socialMedia.pubId', optionSocialMedia[0].value];
            filterValue.push(filterCriteria);
        }
        if (role === RoleList.CampaignExecutive) {
            var filterCriteria = ['team.pubId', optionTeam && optionTeam[0].value];
            filterValue.push(filterCriteria);
        }
        else {
            if (team && team.data && team.data.length > 0) {
                var filterCriteria = ['team.pubId', optionTeam && optionTeam[0].value];
                filterValue.push(filterCriteria);
            }
        }
        if (date) {
            var filterCriteria = ['date', date];
            filterValue.push(filterCriteria);
        }
        setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterValue) }));
    }, []);
    return (_jsxs(Fragment, { children: [_jsx(PageTitle, __assign({ breadcrumbs: usersBreadcrumbs }, { children: "Transfer" })), _jsxs(KTCard, { children: [_jsx("div", __assign({ className: 'd-flex flex-column' }, { children: _jsxs("div", __assign({ className: 'card-header border-0 pt-6 d-flex flex-row' }, { children: [_jsxs("div", __assign({ className: 'card-title flex' }, { children: [_jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Input, { placeholder: 'Search', type: 'text', name: 'keyword', value: filter.keyword, onChange: function (e) {
                                                    handleFilterChange(e);
                                                } }) })), _jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Select, { name: 'Type', onChange: function (e) {
                                                    handleFilterChange(e, e.target.value);
                                                    // changeTransferType('bankType')
                                                }, optionList: ProtectedUrl('transfer.view') && ProtectedUrl('transfer.transfer.view')
                                                    ? optionTransferFType
                                                    : optionTransferCEType, defaultValue: ProtectedUrl('transfer.view') && ProtectedUrl('transfer.transfer.view')
                                                    ? optionTransferFType[0].value
                                                    : optionTransferCEType[0].value }) })), _jsx("div", __assign({ className: 'card-title' }, { children: optionSocialMedia && optionSocialMedia.length > 0 && (_jsx(Select, { name: 'division.socialMedia.pubId', onChange: function (e) {
                                                    handleMultiDimensionalFilterChange(e);
                                                }, optionList: optionSocialMedia, defaultValue: optionSocialMedia[0].value, isPlaceholder: false })) })), _jsx("div", __assign({ className: 'card-title' }, { children: optionTeam && optionTeam.length > 0 && (_jsx(Select, { name: 'team.pubId', onChange: function (e) {
                                                    handleMultiDimensionalFilterChange(e);
                                                }, placeholder: 'Filter by Team', optionList: optionTeam, defaultValue: optionTeam[0].value, isPlaceholder: false })) })), ProtectedUrl('transfer.view') && (transferType == transferOptions.input || transferType == transferOptions.belum || transferType == transferOptions.upcoming) && (_jsx("div", __assign({ className: 'mycard-title' }, { children: _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs }, { children: _jsx(DatePicker, { value: date, inputFormat: 'DD/MM/YYYY', dayOfWeekFormatter: function (day) { return day; }, onChange: function (e) {
                                                        onChangeDate(e, 'date');
                                                    }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { className: 'product-datepicker form-control' }))); } }) })) }))), _jsx("div", __assign({ className: 'card-title' }, { children: ProtectedUrl('transfer.view') && transferType == transferOptions.done && (_jsx(Select, { name: 'bankType', onChange: function (e) {
                                                    //here
                                                    handleFilterChange(e);
                                                }, optionList: optionBankList, defaultValue: optionBankList[0].value })) }))] })), _jsx("div", __assign({ className: 'card-toolbar justify-content-end' }, { children: (ProtectedUrl('transfer.view') &&
                                        ProtectedUrl('transfer.transfer.view') &&
                                        transferType == transferOptions.input) ||
                                        transferType == transferOptions.upcoming ||
                                        transferType == transferOptions.oto ? (_jsx(Fragment, { children: _jsx("a", __assign({ className: 'btn btn-dark btn-sm mx-2', title: 'cetak', onClick: function (e) {
                                                handleCetak(e);
                                            } }, { children: "Cetak" })) })) : ('') }))] })) })), _jsx("div", __assign({ className: 'card-body py-4' }, { children: _jsx(DataTable, { title: ' ', columns: columns, persistTableHead: true, noDataComponent: _jsx(NotFound, {}), data: transfers ? transfers.data : [], contextActions: contextActions, onSelectedRowsChange: handleRowSelected, clearSelectedRows: toggleCleared, noHeader: selectedRows.length > 0 ? false : true, selectableRows: ProtectedUrl('transfer.view') &&
                                (transferType == transferOptions.input ||
                                    transferType == transferOptions.belum ||
                                    transferType == transferOptions.oto ||
                                    transferType == transferOptions.upcoming) &&
                                true, progressPending: isLoading, progressComponent: _jsx(Loading, {}), pagination: true, paginationServer: true, paginationDefaultPage: filter.page, paginationTotalRows: filter.filterBy === ''
                                ? transfers && transfers.recordsTotal
                                : transfers && transfers.recordsFiltered, paginationPerPage: filter.pageSize, paginationRowsPerPageOptions: [1, 10, 20, 30, 40, 50, 100], paginationComponentOptions: paginationOptions, onChangeRowsPerPage: handlePageSizeChange, onChangePage: handlePageChange }, transfers && transfers.data.length) }))] }), _jsx(DrawerForm, { tabName: tabName, useForm: fixTransferFormik, isOpen: isOpenFixTransfer, handleClose: setIsOpenFixTransfer, onSubmit: fixTransferFormik.handleSubmit, component: FailedForm, idForEdit: '' }), _jsx(DrawerForm, { tabName: tabName, useForm: failsInputFormik, isOpen: isOpenFailTransfer, handleClose: setIsOpenFailTransfer, onSubmit: failsInputFormik.handleSubmit, component: GagalInputForm, idForEdit: '' }), _jsx(DrawerForm, { tabName: tabName, useForm: doneTransferFormik, isOpen: isOpenDoneTransferForm, handleClose: setIsOpenDoneTransferForm, onSubmit: doneTransferFormik.handleSubmit, component: TransferForm, idForEdit: '' }), _jsx(DrawerForm, { tabName: tabName, useForm: uploadProofTransferFormik, isOpen: isOpenUploadTransferForm, handleClose: setIsOpenUploadTransferForm, onSubmit: uploadProofTransferFormik.handleSubmit, component: UploadForm, idForEdit: '' }), _jsx(DrawerForm, { tabName: tabName, useForm: refundTransferFormik, isOpen: isOpenRefundTransferForm, handleClose: setIsOpenRefundTransferForm, onSubmit: refundTransferFormik.handleSubmit, component: RefundForm, idForEdit: '' }), _jsx(DrawerForm, { tabName: tabName, useForm: HoldFormik, isOpen: isOpenAdd, handleClose: setIsOpenAdd, onSubmit: HoldFormik.handleSubmit, component: HoldForm, idForEdit: '' }), _jsx(TemplateCetak, { isOpen: isOpenCetak, handleClose: function () {
                    setIsOpenCetak(false);
                }, type: transferType })] }));
};
export default TransferIndex;
